import {
    Divider,
    Stack,
    Text,
    Container,
    Box,
    HStack,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Flex,
    Badge,
    Image,
    List,
    ListItem,
    ListIcon,
    Button,
    ButtonGroup,
    Center,
  } from "@chakra-ui/react";
  import { ChevronRightIcon } from "@chakra-ui/icons";
  import { Fade } from "react-reveal";
  import { useState, useEffect } from "react";
  import ExperienceArray from "./ExperienceArray";
  import TagsArray from "./TagsArray";
  
  export default function Experience({ color }) {
    const experience = ExperienceArray();
    const options = TagsArray("ExperienceTags");
    const [selected, setSelected] = useState("");
  
    useEffect(() => {
      if (options.length > 0) {
        setSelected(options[0].value);
      }
    }, [options]);
    
    const handleSelected = (value) => {
      setSelected(value);
    };
  
    return (
      <>
        <Container maxW={"3xl"} id="experience">
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 14 }}
            pb={{ base: 20, md: 36 }}
          >
            <Stack align="center" direction="row" px={4}>
              <HStack mx={4}>
                <Text color={`${color}.400`} fontWeight={800}>
                  03
                </Text>
                <Text fontWeight={800}>Education</Text>
              </HStack>
              <Divider orientation="horizontal" />
            </Stack>
            <Center px={4}>
            <Container>
 
 <ul>
 <h1>Brainstation - Software Engineering Diploma</h1>

 </ul>
 


 
</Container>

          
          
            </Center>

            <Center px={4}>
            <Container>
 <ul>
  <h1>Humber College - Bachelor of Digital Communications</h1>
 </ul>
 
</Container>

            </Center>
         
          </Stack>
        </Container>
      </>
    );
  }
  